export class USAComboBox {
    constructor(comboBox) {
        this.lang = document.documentElement.lang;
        this.toggleClickListener = () => this.handleToggle();
        this.clearClickListener = () => this.handleClear();
        this.mouseoverListener = (e) => this.handleMouseover(e);
        this.optionClickListener = (e) => this.handleOptionClick(e);
        this.inputClickListener = (e) => this.handleInputClick(e);
        this.inputKeydownListener = (e) => this.handleInputKeydown(e);
        this.inputEventListener = (e) => this.handleInputChange(e);
        this.outsideClickListener = (e) => this.handleOutsideClick(e);
        this.comboBox = comboBox;
        const existingComponent = USAComboBox._components.get(this.comboBox);
        if (existingComponent) {
            existingComponent.unregister();
        }
        const selectEl = this.comboBox.querySelector('select');
        if (!selectEl) {
            throw new Error('Combo box is missing inner select');
        }
        this.isOutside = false;
        this.select = selectEl;
        this.selectEl = selectEl.cloneNode(true);
        this.listboxId = `${this.select.id}--list`;
        this.assistiveHintID = `${this.select.id}--assistiveHint`;
        const label = document.querySelector(`label[for='${this.select.id}']`);
        if (!label) {
            throw new Error('Combo box is missing accessible label');
        }
        this.label = label;
        this.labelEl = label.cloneNode(true);
        this.label.id = `${this.select.id}-label`;
        this.createComboBox();
        USAComboBox._components.set(this.comboBox, this);
    }
    static create(comboBox) {
        if (!(comboBox instanceof HTMLElement)) {
            throw 'Element is not an HTMLElement';
        }
        return this._components.get(comboBox) || new this(comboBox);
    }
    static createAll() {
        const comboBoxes = Array.from(document.getElementsByClassName('usa-combo-box'));
        return comboBoxes.map((comboBox) => this.create(comboBox));
    }
    static autoInit() {
        document.addEventListener('DOMContentLoaded', () => {
            const comboBoxes = Array.from(document.getElementsByClassName('usa-combo-box'));
            comboBoxes.forEach((comboBox) => {
                this.create(comboBox);
            });
        });
    }
    unregister() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this.removeEventListeners();
        this.resetSelectState();
        (_a = this.input) === null || _a === void 0 ? void 0 : _a.remove();
        (_b = this.clearButtonWrapper) === null || _b === void 0 ? void 0 : _b.remove();
        (_c = this.clearButton) === null || _c === void 0 ? void 0 : _c.remove();
        (_d = this.buttonSeparator) === null || _d === void 0 ? void 0 : _d.remove();
        (_e = this.toggleButtonWrapper) === null || _e === void 0 ? void 0 : _e.remove();
        (_f = this.toggleButton) === null || _f === void 0 ? void 0 : _f.remove();
        (_g = this.listbox) === null || _g === void 0 ? void 0 : _g.remove();
        (_h = this.announcer) === null || _h === void 0 ? void 0 : _h.remove();
        (_j = this.assistiveHint) === null || _j === void 0 ? void 0 : _j.remove();
    }
    removeEventListeners() {
        if (this.input) {
            this.input.removeEventListener('click', this.inputClickListener);
            this.input.removeEventListener('keydown', this.inputKeydownListener);
            this.input.removeEventListener('change', this.inputEventListener);
        }
        if (this.toggleButton) {
            this.toggleButton.removeEventListener('click', this.toggleClickListener);
        }
        if (this.clearButton) {
            this.clearButton.removeEventListener('click', this.clearClickListener);
        }
        if (this.listOptions) {
            this.listOptions.forEach((option) => {
                option.removeEventListener('mouseover', this.mouseoverListener);
            });
        }
        document.removeEventListener('click', this.outsideClickListener, true);
    }
    getValue() {
        return this.select.value;
    }
    getSelectedOptions() {
        return this.select.selectedOptions;
    }
    getOptions() {
        return this.select.options;
    }
    setSelectedByValue(value) {
        const option = this.comboBox.querySelector(`[data-value='${value}']`);
        if (option) {
            this.setComboBox(option);
            this.hideListbox();
        }
    }
    createComboBox() {
        this.comboBox.appendChild(this.createInput());
        this.comboBox.appendChild(this.createClearButtonWrapper());
        this.comboBox.appendChild(this.createButtonSeparator());
        this.comboBox.appendChild(this.createToggleButtonWrapper());
        this.comboBox.appendChild(this.createAnnouncer());
        this.comboBox.appendChild(this.createListbox());
        this.comboBox.appendChild(this.createAssistiveHint());
        this.hideSelect();
        this.hideListbox();
        document.addEventListener('click', this.outsideClickListener, true);
    }
    hideSelect() {
        this.select.setAttribute('aria-hidden', 'true');
        this.select.setAttribute('tabindex', '-1');
        this.select.setAttribute('autocomplete', 'off');
        this.select.classList.add('usa-sr-only', 'usa-combo-box__select');
        this.select.id = '';
    }
    resetSelectState() {
        this.select.replaceWith(this.selectEl);
        this.label.replaceWith(this.labelEl);
    }
    createInput() {
        this.input = document.createElement('input');
        this.input.setAttribute('id', this.select.id);
        this.input.setAttribute('aria-owns', this.listboxId);
        this.input.setAttribute('aria-controls', this.listboxId);
        this.input.setAttribute('aria-autocomplete', 'list');
        this.input.setAttribute('aria-describedby', this.assistiveHintID);
        this.input.setAttribute('aria-expanded', 'false');
        this.input.setAttribute('aria-activedescendant', '');
        this.input.setAttribute('autocapitalize', 'off');
        this.input.setAttribute('autocomplete', 'off');
        this.input.setAttribute('class', 'usa-combo-box__input');
        this.input.setAttribute('type', 'text');
        this.input.setAttribute('role', 'combobox');
        this.input.required = this.select.required;
        this.input.disabled = this.select.disabled;
        this.input.addEventListener('click', this.inputClickListener);
        this.input.addEventListener('keydown', this.inputKeydownListener);
        this.input.addEventListener('input', this.inputEventListener);
        return this.input;
    }
    createClearButtonWrapper() {
        this.clearButtonWrapper = document.createElement('span');
        this.clearButtonWrapper.setAttribute('class', 'usa-combo-box__clear-input__wrapper');
        this.clearButtonWrapper.setAttribute('tabindex', '-1');
        this.clearButtonWrapper.appendChild(this.createClearButton());
        return this.clearButtonWrapper;
    }
    createClearButton() {
        const label = this.lang === 'es' ? 'Borrar selección' : 'Clear the select contents';
        this.clearButton = document.createElement('button');
        this.clearButton.setAttribute('class', 'usa-combo-box__clear-input');
        this.clearButton.setAttribute('aria-label', label);
        this.clearButton.setAttribute('type', 'button');
        this.clearButton.addEventListener('click', this.clearClickListener);
        this.clearButton.innerHTML = '&nbsp;';
        this.clearButton.disabled = this.select.disabled;
        this.clearButton.hidden = !this.select.value;
        return this.clearButton;
    }
    createButtonSeparator() {
        this.buttonSeparator = document.createElement('span');
        this.buttonSeparator.setAttribute('class', 'usa-combo-box__input-button-separator');
        this.buttonSeparator.innerHTML = '&nbsp;';
        return this.buttonSeparator;
    }
    createToggleButtonWrapper() {
        this.toggleButtonWrapper = document.createElement('span');
        this.toggleButtonWrapper.setAttribute('class', 'usa-combo-box__toggle-list__wrapper');
        this.toggleButtonWrapper.setAttribute('tabindex', '-1');
        this.toggleButtonWrapper.appendChild(this.createToggleButton());
        return this.toggleButtonWrapper;
    }
    createToggleButton() {
        const label = this.lang === 'es'
            ? 'Pulse la tecla de flecha hacia abajo para desplegar la lista de las opciones'
            : 'Toggle the dropdown list';
        this.toggleButton = document.createElement('button');
        this.toggleButton.setAttribute('tabindex', '-1');
        this.toggleButton.setAttribute('class', 'usa-combo-box__toggle-list');
        this.toggleButton.setAttribute('aria-label', label);
        this.toggleButton.setAttribute('aria-controls', this.listboxId);
        this.toggleButton.setAttribute('aria-expanded', 'false');
        this.toggleButton.setAttribute('type', 'button');
        this.toggleButton.addEventListener('click', this.toggleClickListener);
        this.toggleButton.innerHTML = '&nbsp;';
        this.toggleButton.disabled = this.select.disabled;
        return this.toggleButton;
    }
    createListbox() {
        this.listbox = document.createElement('ul');
        this.listbox.setAttribute('tabindex', '-1');
        this.listbox.setAttribute('id', this.listboxId);
        this.listbox.setAttribute('class', 'usa-combo-box__list');
        this.listbox.setAttribute('role', 'listbox');
        this.listbox.setAttribute('aria-labelledby', this.label.id);
        const options = this.createOptions();
        this.updateListbox(options);
        const selectedOption = [...options].find((option) => option.dataset.selected === 'true');
        if (selectedOption) {
            this.setComboBox(selectedOption);
            this.hideListbox();
        }
        return this.listbox;
    }
    createOptions() {
        const selectOptions = Array.from(this.select.options);
        const filteredOptions = [...selectOptions].filter((option) => !option.disabled &&
            option.hasAttribute('value') &&
            option.value &&
            option.value.trim() !== '');
        this.listOptions = [...filteredOptions].map((option, index) => {
            const optionId = `${this.listboxId}--option-${index + 1}`;
            const li = document.createElement('li');
            li.setAttribute('aria-setsize', `${filteredOptions.length}`);
            li.setAttribute('aria-posinset', `${index + 1}`);
            li.setAttribute('aria-selected', 'false');
            li.setAttribute('id', optionId);
            li.setAttribute('class', 'usa-combo-box__list-option');
            li.setAttribute('tabindex', '-1');
            li.setAttribute('role', 'option');
            li.setAttribute('data-selected', `${option.selected}`);
            li.setAttribute('data-value', option.value);
            li.textContent = option.textContent;
            li.addEventListener('mouseover', this.mouseoverListener);
            li.addEventListener('click', this.optionClickListener);
            return li;
        });
        return this.listOptions;
    }
    createAnnouncer() {
        this.announcer = document.createElement('div');
        this.announcer.setAttribute('class', 'usa-combo-box__status usa-sr-only');
        this.announcer.setAttribute('role', 'status');
        return this.announcer;
    }
    createAssistiveHint() {
        this.assistiveHint = document.createElement('span');
        this.assistiveHint.setAttribute('id', this.assistiveHintID);
        this.assistiveHint.setAttribute('class', 'usa-sr-only');
        this.assistiveHint.textContent =
            this.lang === 'es'
                ? 'Si hay campos que se autocompletan, use las teclas de flecha para conocer las opciones y pulse la tecla Enter para seleccionar. En los dispositivos táctiles, navegue por la pantalla al tacto o deslizando un dedo.'
                : 'When autocomplete results are available use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.';
        return this.assistiveHint;
    }
    updateAnnouncer(termCount) {
        if (!this.announcer) {
            this.unregister();
            throw new Error('Combo box announcer error');
        }
        if (termCount >= 1) {
            this.announcer.textContent =
                this.lang === 'es'
                    ? `${termCount.toString()} sugerencias automáticas. Use flecha arriba o flecha abajo para escuchar las opciones.`
                    : `${termCount.toString()} suggestions found, use up and down arrows to review.`;
        }
        else {
            this.announcer.textContent = '';
        }
    }
    handleToggle() {
        this.toggleListbox();
        if (this.input && this.input.value) {
            this.filterListbox(this.input.value);
        }
    }
    handleClear() {
        this.unsetComboBox();
    }
    handleMouseover(event) {
        const mouseEvent = event;
        const target = mouseEvent.currentTarget;
        this.highlightOption(target);
    }
    handleOptionClick(event) {
        const mouseEvent = event;
        const target = mouseEvent.currentTarget;
        this.setComboBox(target);
        this.hideListbox();
    }
    handleInputClick(event) {
        const mouseEvent = event;
        const input = mouseEvent.currentTarget;
        input.focus();
        this.showListbox();
        this.selectedOption = undefined;
        if (input.value) {
            this.filterListbox(input.value);
        }
    }
    handleInputKeydown(event) {
        const keyboardEvent = event;
        const input = keyboardEvent.currentTarget;
        switch (keyboardEvent.key) {
            case 'ArrowDown': {
                keyboardEvent.preventDefault();
                this.showListbox();
                if (input.value) {
                    this.filterListbox(input.value);
                }
                if (this.selectedOption) {
                    this.highlightOption(this.selectedOption);
                    this.scrollToOption(this.selectedOption);
                    this.selectedOption = undefined;
                }
                if (!this.selectedOption) {
                    const firstChild = this.listbox && !this.highlightedOption
                        ? this.listbox.firstChild
                        : undefined;
                    const nextSibling = this.highlightedOption
                        ? this.highlightedOption.nextSibling
                        : undefined;
                    const option = nextSibling || firstChild;
                    if (option) {
                        this.highlightOption(option);
                        this.scrollToOption(option);
                    }
                }
                break;
            }
            case 'ArrowUp': {
                keyboardEvent.preventDefault();
                this.showListbox();
                if (input.value) {
                    this.filterListbox(input.value);
                }
                const lastChild = this.listbox && !this.highlightedOption
                    ? this.listbox.lastChild
                    : undefined;
                const prevSibling = this.highlightedOption
                    ? this.highlightedOption.previousSibling
                    : undefined;
                const option = prevSibling || lastChild;
                if (option) {
                    this.highlightOption(option);
                    this.scrollToOption(option);
                }
                break;
            }
            case 'Enter': {
                keyboardEvent.preventDefault();
                if (this.highlightedOption) {
                    this.setComboBox(this.highlightedOption);
                    this.hideListbox();
                }
                break;
            }
            case 'Escape': {
                if (this.listbox) {
                    this.isOutside = true;
                    this.listbox.hidden ? this.unsetComboBox() : this.hideListbox(true);
                }
                break;
            }
            case 'Tab': {
                if (!keyboardEvent.shiftKey) {
                    this.hideListbox(true);
                }
                break;
            }
        }
    }
    handleInputChange(event) {
        const inputEvent = event;
        const input = inputEvent.currentTarget;
        this.showListbox();
        this.filterListbox(input.value);
        if (this.listbox && this.listbox.firstChild) {
            this.highlightOption(this.listbox.firstChild);
        }
        this.comboBox.dispatchEvent(new CustomEvent('usa-combo-box:input:text-change', {
            bubbles: true,
            detail: {
                comboBox: this.comboBox,
                inputValue: input.value,
            },
        }));
    }
    handleOutsideClick(event) {
        const target = event.target;
        if (this.listbox &&
            !this.listbox.hidden &&
            !this.comboBox.contains(target)) {
            this.isOutside = true;
            this.hideListbox(true);
        }
    }
    toggleListbox() {
        if (this.listbox) {
            this.listbox.hidden ? this.showListbox() : this.hideListbox(true);
        }
        if (this.input) {
            this.input.focus();
        }
    }
    showListbox() {
        if (!this.listbox || !this.input || !this.toggleButton) {
            this.unregister();
            throw new Error('Combo box show listbox error');
        }
        if (!this.listbox.hidden) {
            return;
        }
        this.input.setAttribute('aria-expanded', 'true');
        this.toggleButton.setAttribute('aria-expanded', 'true');
        this.listbox.hidden = false;
        this.isOutside = false;
        this.comboBox.dispatchEvent(new CustomEvent('usa-combo-box:listbox:expanded', {
            bubbles: true,
            detail: {
                comboBox: this.comboBox,
                inputValue: this.input.value,
            },
        }));
    }
    hideListbox(validateOption = false) {
        if (!this.listbox || !this.input || !this.toggleButton) {
            this.unregister();
            throw new Error('Combo box hide listbox error');
        }
        if (this.listbox.hidden) {
            return;
        }
        this.input.setAttribute('aria-expanded', 'false');
        this.toggleButton.setAttribute('aria-expanded', 'false');
        this.listbox.hidden = true;
        this.listbox.scrollTop = 0;
        this.removeHighlightedOption();
        if (this.listbox && this.listOptions) {
            this.listbox.replaceChildren(...this.listOptions);
            this.updateAnnouncer(this.listOptions.length);
        }
        if (validateOption) {
            this.checkComboBoxOption();
        }
        this.comboBox.dispatchEvent(new CustomEvent('usa-combo-box:listbox:collapsed', {
            bubbles: true,
            detail: {
                comboBox: this.comboBox,
                inputValue: this.input.value,
            },
        }));
    }
    filterListbox(value) {
        if (!this.listOptions || !this.input) {
            this.unregister();
            throw new Error('Combo box filterListbox error');
        }
        this.filteredListOptions = this.listOptions
            .filter((option) => option.textContent.toLowerCase().includes(value.toLowerCase()))
            .map((option, index, filtered) => {
            option.setAttribute('aria-posinset', `${index + 1}`);
            option.setAttribute('aria-setsize', `${filtered.length}`);
            return option;
        });
        if (this.filteredListOptions && this.filteredListOptions.length) {
            this.updateListbox(this.filteredListOptions);
        }
        else {
            const li = document.createElement('li');
            li.setAttribute('class', 'usa-combo-box__list-option usa-combo-box__list-option--no-results');
            li.setAttribute('tabindex', '-1');
            li.setAttribute('role', 'option');
            li.textContent =
                this.lang === 'es' ? 'No hay resultados' : 'No results found';
            if (this.listbox) {
                this.listbox.replaceChildren(li);
                this.updateAnnouncer(0);
            }
            this.removeHighlightedOption();
            this.comboBox.dispatchEvent(new CustomEvent('usa-combo-box:listbox:no-results', {
                bubbles: true,
                detail: {
                    comboBox: this.comboBox,
                    inputValue: this.input.value,
                },
            }));
        }
    }
    updateListbox(options) {
        if (this.listbox) {
            this.listbox.replaceChildren(...options);
            this.updateAnnouncer(options.length);
        }
    }
    highlightOption(listItem) {
        if (!listItem || !listItem.getAttribute('data-value')) {
            return;
        }
        this.removeHighlightedOption();
        this.highlightedOption = listItem;
        this.highlightedOption.classList.add('usa-combo-box__list-option--focused', 'usa-combo-box__list-option--selected');
        if (this.input) {
            const activeDescendant = listItem.id;
            this.input.setAttribute('aria-activedescendant', activeDescendant);
        }
        this.highlightedOption.setAttribute('aria-selected', 'true');
    }
    removeHighlightedOption() {
        var _a, _b;
        (_a = this.highlightedOption) === null || _a === void 0 ? void 0 : _a.classList.remove('usa-combo-box__list-option--focused', 'usa-combo-box__list-option--selected');
        if (this.input) {
            this.input.setAttribute('aria-activedescendant', '');
        }
        (_b = this.highlightedOption) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-selected', 'false');
        this.highlightedOption = undefined;
    }
    scrollToOption(listItem) {
        if (this.listbox) {
            const listbox = this.listbox;
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('is-visible');
                        observer.unobserve(entry.target);
                    }
                    else {
                        listItem.scrollIntoView({
                            behavior: 'instant',
                            block: 'nearest',
                            inline: 'start',
                        });
                    }
                });
            }, {
                root: listbox,
                threshold: 0.5,
            });
            observer.observe(listItem);
        }
    }
    checkComboBoxOption() {
        if (this.input &&
            this.input.getAttribute('data-value') !== this.select.value) {
            this.select.value
                ? this.setSelectedByValue(this.select.value)
                : this.clearInput(this.input);
        }
    }
    clearInput(input) {
        const previousInputValue = input.value;
        this.resetComboBox();
        this.comboBox.dispatchEvent(new CustomEvent('usa-combo-box:input:text-cleared', {
            bubbles: true,
            detail: {
                comboBox: this.comboBox,
                selected: this.select.selectedOptions,
                previousInputValue: previousInputValue,
            },
        }));
    }
    setComboBox(listItem) {
        const previouslySelected = Object.assign({}, this.select.selectedOptions);
        this.selectedOption = listItem;
        const value = listItem.dataset.value;
        const text = listItem.textContent;
        this.select.value = value;
        this.comboBox.classList.add('usa-combo-box--pristine');
        if (this.clearButton && this.select.value) {
            this.clearButton.hidden = false;
        }
        if (this.input) {
            this.input.value = text;
        }
        if (!this.isOutside) {
            this.comboBox.dispatchEvent(new CustomEvent('usa-combo-box:selected', {
                bubbles: true,
                detail: {
                    comboBox: this.comboBox,
                    previouslySelected: previouslySelected,
                    selected: this.select.selectedOptions,
                    inputValue: text,
                },
            }));
        }
    }
    unsetComboBox() {
        const previouslySelected = Object.assign({}, this.select.selectedOptions);
        this.resetComboBox();
        this.comboBox.dispatchEvent(new CustomEvent('usa-combo-box:unselected', {
            bubbles: true,
            detail: {
                comboBox: this.comboBox,
                previouslySelected: previouslySelected,
            },
        }));
    }
    resetComboBox() {
        this.selectedOption = undefined;
        this.removeHighlightedOption();
        this.comboBox.classList.remove('usa-combo-box--pristine');
        this.select.value = '';
        if (this.listbox && this.listOptions) {
            this.listbox.replaceChildren(...this.listOptions);
            this.updateAnnouncer(this.listOptions.length);
        }
        if (this.clearButton) {
            this.clearButton.hidden = true;
        }
        if (this.input) {
            this.input.value = '';
            this.input.focus();
        }
    }
}
USAComboBox._components = new Map();
